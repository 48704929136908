import { Interface } from '@ethersproject/abi'
import { ChainId, Currency, CurrencyAmount } from '@uniswap/sdk-core'
import IUniswapV2PairJSON from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { Pair } from '@uniswap/v2-sdk'
import { useWeb3React } from '@web3-react/core'
import { useMultipleContractSingleData } from 'lib/hooks/multicall'
import { useMemo } from 'react'

const PAIR_INTERFACE = new Interface(IUniswapV2PairJSON.abi)

export enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID,
}

export function useV2Pairs(currencies: [Currency | undefined, Currency | undefined][]): [PairState, Pair | null][] {
  const { chainId } = useWeb3React()
  const tokens = useMemo(
    () => currencies.map(([currencyA, currencyB]) => [currencyA?.wrapped, currencyB?.wrapped]),
    [currencies]
  )

  // const pairAddresses = useMemo(
  //   () =>
  //     tokens.map(([tokenA, tokenB]) => {
  //       console.log(tokenA)
  //       console.log(tokenB)

  //       if (tokenA) {
  //         console.log(V2_FACTORY_ADDRESSES[tokenA.chainId])
  //       }

  //       return tokenA &&
  //         tokenB &&
  //         tokenA.chainId === tokenB.chainId &&
  //         !tokenA.equals(tokenB) &&
  //         V2_FACTORY_ADDRESSES[tokenA.chainId]
  //         ? computePairAddress({ factoryAddress: V2_FACTORY_ADDRESSES[tokenA.chainId], tokenA, tokenB })
  //         : undefined
  //     }),
  //   [tokens]
  // )

  // TODO Update
  const pairs: Record<number, string[]> = {
    [ChainId.ARBITRUM_GOERLI]: ['0x4295E98AdE7645E05632569935E1F46Bd12791Cc'],
    [ChainId.ARBITRUM_ONE]: ['0xE1631EEa916f0cadD1ad29b3Ee712C4b3aD095A2'],
  }

  const pairAddresses = (chainId !== undefined && tokens.length > 0 ? pairs[chainId] : []) ?? []

  const results = useMultipleContractSingleData(pairAddresses, PAIR_INTERFACE, 'getReserves')

  return useMemo(() => {
    return results.map((result, i) => {
      const { result: reserves, loading } = result
      const tokenA = tokens[i][0]
      const tokenB = tokens[i][1]

      if (loading) return [PairState.LOADING, null]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
      if (!reserves) return [PairState.NOT_EXISTS, null]
      const { reserve0, reserve1 } = reserves
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
      return [
        PairState.EXISTS,
        new Pair(
          CurrencyAmount.fromRawAmount(token0, reserve0.toString()),
          CurrencyAmount.fromRawAmount(token1, reserve1.toString())
        ),
      ]
    })
  }, [results, tokens])
}

export function useV2Pair(tokenA?: Currency, tokenB?: Currency): [PairState, Pair | null] {
  const inputs: [[Currency | undefined, Currency | undefined]] = useMemo(() => [[tokenA, tokenB]], [tokenA, tokenB])
  return useV2Pairs(inputs)[0]
}
