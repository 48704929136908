import { user } from '@uniswap/analytics'
import { CustomUserProperties } from '@uniswap/analytics-events'
import Loader from 'components/Icons/LoadingSpinner'
import { useFeatureFlagsIsLoaded } from 'featureFlags'
import { useBag } from 'nft/hooks/useBag'
import { Suspense, useEffect, useState } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { useRouterPreference } from 'state/user/hooks'
import styled from 'styled-components/macro'
import { useIsDarkMode } from 'theme/components/ThemeToggle'
import { flexRowNoWrap } from 'theme/styles'
import { Z_INDEX } from 'theme/zIndex'

import ErrorBoundary from '../components/ErrorBoundary'
import NavBar, { PageTabs } from '../components/NavBar'
import Polling from '../components/Polling'
import Popups from '../components/Popups'
import DarkModeQueryParamReader from '../theme/components/DarkModeQueryParamReader'
import NotFound from './NotFound'
import Swap from './Swap'

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: ${({ theme }) => theme.navHeight}px 0px 5rem 0px;
  align-items: center;
  flex: 1;
`

const MobileBottomBar = styled.div`
  z-index: ${Z_INDEX.sticky};
  position: fixed;
  display: flex;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  justify-content: space-between;
  padding: 4px 8px;
  height: ${({ theme }) => theme.mobileBottomBarHeight}px;
  background: ${({ theme }) => theme.backgroundSurface};
  border-top: 1px solid ${({ theme }) => theme.backgroundOutline};

  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: none;
  }
`

const HeaderWrapper = styled.div<{ transparent?: boolean }>`
  ${flexRowNoWrap};
  background-color: ${({ theme, transparent }) => !transparent && theme.backgroundSurface};
  border-bottom: ${({ theme, transparent }) => !transparent && `1px solid ${theme.backgroundOutline}`};
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: ${Z_INDEX.dropdown};
`

export default function App() {
  const isLoaded = useFeatureFlagsIsLoaded()

  const { pathname } = useLocation()
  const isDarkMode = useIsDarkMode()
  const [routerPreference] = useRouterPreference()
  const [scrolledState, setScrolledState] = useState(false)

  // useAnalyticsReporter()

  useEffect(() => {
    window.scrollTo(0, 0)
    setScrolledState(false)
  }, [pathname])

  // useEffect(() => {
  //   // User properties *must* be set before sending corresponding event properties,
  //   // so that the event contains the correct and up-to-date user properties.
  //   user.set(CustomUserProperties.USER_AGENT, navigator.userAgent)
  //   user.set(CustomUserProperties.BROWSER, getBrowser())
  //   user.set(CustomUserProperties.SCREEN_RESOLUTION_HEIGHT, window.screen.height)
  //   user.set(CustomUserProperties.SCREEN_RESOLUTION_WIDTH, window.screen.width)

  //   // Service Worker analytics
  //   const isServiceWorkerInstalled = Boolean(window.navigator.serviceWorker?.controller)
  //   const isServiceWorkerHit = Boolean((window as any).__isDocumentCached)
  //   const serviceWorkerProperty = isServiceWorkerInstalled ? (isServiceWorkerHit ? 'hit' : 'miss') : 'uninstalled'

  //   const pageLoadProperties = { service_worker: serviceWorkerProperty }
  //   sendAnalyticsEvent(SharedEventName.APP_LOADED, pageLoadProperties)
  //   const sendWebVital =
  //     (metric: string) =>
  //     ({ delta }: Metric) =>
  //       sendAnalyticsEvent(SharedEventName.WEB_VITALS, { ...pageLoadProperties, [metric]: delta })
  //   getCLS(sendWebVital('cumulative_layout_shift'))
  //   getFCP(sendWebVital('first_contentful_paint_ms'))
  //   getFID(sendWebVital('first_input_delay_ms'))
  //   getLCP(sendWebVital('largest_contentful_paint_ms'))
  // }, [])

  useEffect(() => {
    user.set(CustomUserProperties.DARK_MODE, isDarkMode)
  }, [isDarkMode])

  useEffect(() => {
    user.set(CustomUserProperties.ROUTER_PREFERENCE, routerPreference)
  }, [routerPreference])

  useEffect(() => {
    const scrollListener = () => {
      setScrolledState(window.scrollY > 0)
    }
    window.addEventListener('scroll', scrollListener)
    return () => window.removeEventListener('scroll', scrollListener)
  }, [])

  const isBagExpanded = useBag((state) => state.bagExpanded)
  const isHeaderTransparent = !scrolledState && !isBagExpanded

  return (
    <ErrorBoundary>
      <DarkModeQueryParamReader />
      <HeaderWrapper transparent={isHeaderTransparent}>
        <NavBar blur={isHeaderTransparent} />
      </HeaderWrapper>
      <BodyWrapper>
        <Popups />
        <Polling />
        {/* <TopLevelModals /> */}
        <Suspense fallback={<Loader />}>
          {isLoaded ? (
            <Routes>
              <Route path="swap" element={<Swap />} />

              <Route path="/" element={<Navigate to="/swap" replace />} />
              <Route path="*" element={<Navigate to="/not-found" replace />} />
              <Route path="/not-found" element={<NotFound />} />
            </Routes>
          ) : (
            <Loader />
          )}
        </Suspense>
      </BodyWrapper>
      <MobileBottomBar>
        <PageTabs />
      </MobileBottomBar>
    </ErrorBoundary>
  )
}
