import { TransactionResponse } from '@ethersproject/abstract-provider'
import { BigNumber } from '@ethersproject/bignumber'
import { t } from '@lingui/macro'
import { Trade } from '@uniswap/router-sdk'
import { Currency, Percent, TradeType } from '@uniswap/sdk-core'
import { FeeOptions } from '@uniswap/v3-sdk'
import { useWeb3React } from '@web3-react/core'
import { UniswapV2Router02 } from 'abis/types'
import V2RouterAbi from 'abis/UniswapV2Router02.json'
import { V2_ROUTER_ADDRESSES } from 'constants/addresses'
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Contract } from 'ethers'
import { useCallback } from 'react'
import { UserRejectedRequestError } from 'utils/errors'
import { didUserReject, swapErrorToUserReadableMessage } from 'utils/swapErrorToUserReadableMessage'

import { PermitSignature } from './usePermitAllowance'

/**
 * Thrown when the user modifies the transaction in-wallet before submitting it.
 * In-wallet calldata modification nullifies any safeguards (eg slippage) from the interface, so we recommend reverting them immediately.
 */
class ModifiedSwapError extends Error {
  constructor() {
    super(
      t`Your swap was modified through your wallet. If this was a mistake, please cancel immediately or risk losing your funds.`
    )
  }
}

interface SwapOptions {
  slippageTolerance: Percent
  deadline?: BigNumber
  permit?: PermitSignature
  feeOptions?: FeeOptions
}

export function useUniversalRouterSwapCallback(
  trade: Trade<Currency, Currency, TradeType> | undefined,
  proof: string[] | true | undefined,
  fiatValues: { amountIn?: number; amountOut?: number },
  options: SwapOptions
) {
  const { account, chainId, provider } = useWeb3React()

  return useCallback(async (): Promise<TransactionResponse> => {
    try {
      if (!account) throw new Error('missing account')
      if (!chainId) throw new Error('missing chainId')
      if (!provider) throw new Error('missing provider')
      if (!trade) throw new Error('missing trade')
      if (!options.deadline) throw new Error('missing deadline')
      if (trade.routes.length === 0) throw new Error('missing routes')
      if (proof === undefined || proof === true) throw new Error('missing proof')

      const contractAddress = V2_ROUTER_ADDRESSES[chainId]

      if (!trade) throw new Error('missing contract address')

      const signer = provider.getUncheckedSigner()
      const contract = new Contract(contractAddress, V2RouterAbi, signer) as UniswapV2Router02

      const response = await contract.swapExactTokensForTokens(
        trade.inputAmount.numerator.toString(),
        trade.minimumAmountOut(options.slippageTolerance, trade.outputAmount).numerator.toString(),
        trade.swaps[0].route.path.map((item) => item.address),
        account,
        options.deadline,
        proof
      )

      return response
    } catch (swapError: unknown) {
      if (swapError instanceof ModifiedSwapError) throw swapError

      // Cancellations are not failures, and must be accounted for as 'cancelled'.
      if (didUserReject(swapError)) {
        // This error type allows us to distinguish between user rejections and other errors later too.
        throw new UserRejectedRequestError(swapErrorToUserReadableMessage(swapError))
      }

      throw new Error(swapErrorToUserReadableMessage(swapError))
    }
  }, [account, chainId, options.deadline, options.slippageTolerance, proof, provider, trade])
}
