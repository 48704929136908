import { ChainId } from '@uniswap/sdk-core'

type AddressMap = {
  [chainId: number]: string
}

// TODO Update
export const V2_ROUTER_ADDRESSES: AddressMap = {
  [ChainId.ARBITRUM_ONE]: '0xa78614e57612F43Be63236a842aC974a44d00f03',
}
