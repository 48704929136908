import { useWeb3React } from '@web3-react/core'
import axios from 'axios'
import { useEffect, useState } from 'react'

const client = axios.create({
  baseURL: 'https://api-esmarket.0xacid.com',
})

export function useProof(): string[] | true | undefined {
  const [proof, setProof] = useState<Record<string, string[] | true>>({})
  const { account } = useWeb3React()

  useEffect(() => {
    if (!account || proof[account]) {
      return
    }

    client
      .get(`/${account}`)
      .then((res) => {
        setProof((value) => {
          return {
            ...value,
            [account]: res.data,
          }
        })
      })
      .catch(() => {
        setProof((value) => {
          return {
            ...value,
            [account]: true,
          }
        })
      })
  }, [account, proof])

  return account ? proof[account] : undefined
}
